<template>
  <div class="map-page">
    <!-- Begin Map Panel -->
    <div class="map-panel">
      <div class="map-panel-top" ref="topPanel">
        <header class="map-panel-header">
          <div class="header-inner">
            <h1 class="logo">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </h1>
            <div class="panel-title">Map</div>
          </div>
        </header>
        <div class="map-panel-filters">
          <div class="filter-items" v-if="filterMode === 'travelerType'">
            <div
              v-for="filter in filters.travelerTypes"
              :key="filter.id"
              @click="() => onClickTravelerTypeFilter(filter)"
              :class="{ active: isSelectedTravelerType(filter) }"
              :style="{ backgroundColor: filter.color }"
              class="filter-item"
            >
              <span>{{ filter.title }}</span>
            </div>
          </div>
          <div class="filter-items" v-if="filterMode === 'category'">
            <div
              v-for="filter in filters.categories"
              :key="filter.id"
              @click="() => onClickCategoryFilter(filter)"
              :class="{ active: isSelectedCategory(filter) }"
              class="filter-item"
            >
              <span>{{ filter.title }}</span>
            </div>
          </div>
          <div class="filter-buttons">
            <button
              class="filter-selection-button reset-button"
              @click="resetSelectedFilters"
            >
              Reset
            </button>
            <button
              class="filter-selection-button select-all-button"
              @click="selectAllFilters"
            >
              Select All
            </button>
            <div
              class="filter-mode-link searchby-categories"
              :class="{ show: filterMode === 'travelerType' }"
              @click="() => changeFilterMode('category')"
            >
              Search by Categories
            </div>
            <div
              class="filter-mode-link searchby-traveler-type"
              :class="{ show: filterMode === 'category' }"
              @click="() => changeFilterMode('travelerType')"
            >
              Search by Traveler Type
            </div>
          </div>
        </div>
        <div class="result-views">
          <div
            class="view-item view-experiences"
            :class="{ active: currentView === 'experience' }"
            @click="() => changeView('experience')"
          >
            Experience Lists
          </div>
          <div
            class="view-item view-snapshots"
            :class="{ active: currentView === 'snapshot' }"
            @click="() => changeView('snapshot')"
          >
            Traveler Snapshots
          </div>
        </div>
      </div>
      <div class="map-panel-results">
        <VuePerfectScrollbar
          :settings="scrollbarSettings"
          :style="{ height: `calc(100vh - ${topPanelHeight}px)` }"
          class="results-list"
          ref="resultsList"
        >
          <div
            v-show="currentView === 'experience'"
            style="width: 100%; height: 100%; position: relative;"
          >
            <div v-if="experiences && experiences.length > 0">
              <div
                v-for="experience in experiences"
                :key="experience.id"
                class="result-item experience"
                @click="() => onClickExperience(experience)"
              >
                <div
                  class="result-thumbnail"
                  :style="{
                    backgroundImage: `url(${experience.thumbnailUrl})`,
                  }"
                ></div>
                <div class="result-detail">
                  <h4 class="result-title">{{ experience.title }}</h4>
                  <div class="result-excerpt">
                    {{ experience.description | truncate }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!experiences || experiences.length < 1"
              class="empty-result"
            >
              Sorry but there is no result.
            </div>
          </div>
          <div v-show="currentView === 'snapshot'">
            <ImagesBoard
              :grid="true"
              :columns="3"
              :gutter="1"
              :loadMore="true"
              :bounds="map.bounds"
            />
          </div>
        </VuePerfectScrollbar>
      </div>
    </div>
    <!-- End Map Panel -->
    <!-- Begin Map Container -->
    <div class="map-container">
      <MapPopup :experience="selectedExperience" @close="onCloseMapPopup" />
      <l-map
        ref="map"
        :center="map.center"
        :zoom="map.zoom"
        :maxZoom="map.maxZoom"
        @leaflet:load="onMapLoaded"
        @update:bounds="updateBounds"
      >
        <l-marker-cluster>
          <l-marker
            v-for="exp in experiences"
            :key="exp.id"
            :lat-lng="exp.latLng"
            @click="() => openMapPopup(exp)"
          />
        </l-marker-cluster>
        <l-tile-layer :url="map.url"></l-tile-layer>
      </l-map>
    </div>
    <!-- End Map Container -->
    <NavrightHome />
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import NavrightHome from "@/components/NavrightHome";
import ImagesBoard from "@/components/ImagesBoard";
import MapPopup from "@/components/MapPopup";
import axios from "axios";
import logo from "../assets/img/logo-mongolia-home-2.png";
import { latLng } from "leaflet";

export default {
  name: "map-page",
  components: {
    VuePerfectScrollbar,
    NavrightHome,
    ImagesBoard,
    MapPopup,
  },
  props: {
    descriptionLength: {
      type: Number,
      default: 100,
    },
  },
  data() {
    const categories = this.$store.state.ref.categories;
    const travelerTypes = this.$store.state.ref.travelerTypes;

    return {
      logo,
      excerptLength: 100,
      scrollbarSettings: {
        maxScrollbarLength: 60,
      },
      focusedExperience: null,
      selectedExperience: null,
      experiences: [],
      currentView: "experience",
      filterMode: "travelerType",
      filters: {
        travelerTypes,
        categories,
      },
      selectedFilter: {
        categories: [],
        travelerTypes: [],
      },
      topPanelHeight: 0,
      map: {
        url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
        zoom: 5,
        maxZoom: 18,
        center: [47.13, 104],
        bounds: null,
      },
      preventFetch: false,
    };
  },
  mounted: function () {
    // Check if has query: exp (focused experience);
    if (this.$route.query.exp) {
      this.focusedExperience = this.$route.query.exp;
    }
    // Check if has query: ac (all countries);
    if (this.$route.query.ac) {
      this.map.zoom = 2;
      this.map.center = [19.7946, 106.5348]; // Europe
    }
    this.adjustResultsListHeight();
  },
  methods: {
    changeView: function (view) {
      this.currentView = view;
    },
    changeFilterMode: function (mode) {
      this.filterMode = mode;
      this.resetSelectedFilters();
    },
    selectAllFilters: function () {
      if (this.filterMode === "category") {
        this.selectedFilter.categories = this.filters.categories.map(
          (f) => f.id
        );
      } else {
        this.selectedFilter.travelerTypes = this.filters.travelerTypes.map(
          (f) => f.id
        );
      }
      this.loadData();
    },
    resetSelectedFilters: function () {
      this.selectedFilter.categories = [];
      this.selectedFilter.travelerTypes = [];
      this.loadData();
    },
    onClickTravelerTypeFilter: function (filter) {
      const index = this.selectedFilter.travelerTypes.indexOf(filter.id);
      if (index > -1) {
        this.selectedFilter.travelerTypes.splice(index, 1);
      } else {
        this.selectedFilter.travelerTypes.push(filter.id);
      }
      this.loadData();
    },
    isSelectedTravelerType: function (filter) {
      return this.selectedFilter.travelerTypes.indexOf(filter.id) > -1;
    },
    onClickCategoryFilter: function (filter) {
      const index = this.selectedFilter.categories.indexOf(filter.id);
      if (index > -1) {
        this.selectedFilter.categories.splice(index, 1);
      } else {
        this.selectedFilter.categories.push(filter.id);
      }
      this.loadData();
    },
    isSelectedCategory: function (filter) {
      return this.selectedFilter.categories.indexOf(filter.id) > -1;
    },
    adjustResultsListHeight: function () {
      this.topPanelHeight = this.$refs.topPanel.clientHeight;
    },
    getBoundsData(bounds) {
      const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();
      return { southWest, northEast };
    },
    onMapLoaded() {
      const bounds = this.$refs.map.mapObject.getBounds();
      this.bounds = this.getBoundsData(bounds);
      this.loadData();
    },
    updateBounds(bounds) {
      this.bounds = this.getBoundsData(bounds);
      this.loadData();
    },
    loadData() {
      if (this.preventFetch) {
        this.preventFetch = false;
        return;
      }

      const { southWest, northEast } = this.bounds;
      const { categories, travelerTypes } = this.selectedFilter;
      const url = process.env.VUE_APP_API_URI;
      const postData = {
        query: `query experiencesInRegion($sw: [Float!]!, $ne: [Float!]!, $categories: [ID], $travelerTypes: [ID]) { experiencesInRegion(sw: $sw, ne: $ne, categories: $categories, travelerTypes: $travelerTypes) { id name title description thumbnailUrl address websiteLink telephone linkUrl position { lat lng } } }`,
        variables: {
          sw: [southWest.lat, southWest.lng],
          ne: [northEast.lat, northEast.lng],
          categories,
          travelerTypes,
        },
      };

      axios
        .post(url, postData, {
          headers: {
            "X-API-Key": process.env.VUE_APP_API_KEY,
          },
        })
        .then((res) => {
          const { experiencesInRegion } = res.data.data;
          experiencesInRegion.forEach((it) => {
            if (it.position) {
              it.latLng = latLng(it.position.lat, it.position.lng);
            }
          });
          this.experiences = experiencesInRegion;
          this.afterLoaded();
        })
        .catch(console.error);
    },
    afterLoaded() {
      if (!this.experiences) return;

      if (this.focusedExperience) {
        const exp = this.experiences.find(
          (it) => it.name === this.focusedExperience
        );
        if (exp) {
          this.setMapCenter(exp.position);
          this.selectedExperience = exp;
          this.focusedExperience = null;
        }
      }
    },
    setMapCenter({ lat, lng }) {
      this.preventFetch = true;
      this.$refs.map.mapObject.panTo(new latLng(lat, lng));
    },
    onClickExperience(experience) {
      this.setMapCenter(experience.position);
      this.openMapPopup(experience);
    },
    openMapPopup(experience) {
      if (this.selectedExperience === experience) {
        this.selectedExperience = null;
      } else {
        this.selectedExperience = experience;
      }
    },
    onCloseMapPopup() {
      this.selectedExperience = null;
    },
  },
  filters: {
    truncate(value) {
      const length = 85;
      return value.length > length
        ? value.substring(0, length) + " ..."
        : value;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-page {
  width: 100%;
  display: flex;

  .map-container {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
  }
  .leaflet-container {
    width: 100%;
    height: 100vh;
    z-index: 1;
  }

  .map-panel {
    width: 466px;
    height: 100vh;
    overflow: hidden;
    background-color: #fff;
    flex: 0 0 auto;
  }

  .result-views {
    display: flex;
    justify-content: flex-end;
    .view-item {
      font-size: 12px;
      color: #e13036;
      padding: 5px 20px;
      border: 1px solid #2c2c2c;
      border-bottom: none;
      cursor: pointer;
      transition: 0.3s ease all;
      user-select: none;
      margin-left: 5px;

      &.active {
        border-color: #e13036;
        background-color: #e13036;
        color: #fff;
      }
    }
  }
  .map-panel-header {
    user-select: none;
    .header-inner {
      width: 72.53%;
      margin: 0 auto;
      padding: 6.65% 0 4.93%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        width: 75px;
        margin: 0;
        flex: 0 0 auto;
        a {
          display: block;
          width: 100%;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      .panel-title {
        font-size: 30px;
        font-weight: bold;
        color: #e13036;
        text-align: center;
        text-transform: uppercase;
        flex: 1 1 auto;
      }
    }
  }
  .map-panel-filters {
    border: 1px solid #d6d6d6;
    border-bottom: none;
    margin: 12px auto;
    display: flex;
    user-select: none;

    .filter-items {
      flex: 1 1 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: row dense;
      width: 100%;

      .filter-item {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        background-color: #e13036;
        border-right: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;

        span {
          padding: 12px;
          display: block;
          cursor: pointer;
          transition: 0.3s ease all;
          color: #000;
          background-color: #fff;
          border-bottom: 1px solid transparent;
          width: 100%;
          height: 100%;
          white-space: nowrap;
        }

        &.active span,
        &:hover span {
          background-color: transparent;
          color: #fff;
        }
      }
    }

    .filter-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      width: 130px;
      border-bottom: 1px solid #d6d6d6;
    }

    .filter-selection-button {
      background-color: #e13036;
      border: 1px solid #e13036;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      padding: 5px;
      margin: 0 auto 5px;
      min-width: 87px;
      cursor: pointer;
      outline: none;
    }

    .filter-mode-link {
      cursor: pointer;
      color: #e13036;
      text-decoration: underline;
      font-size: 10px;
      margin: 24px auto 0;
      display: none;
      &.show {
        display: block;
      }
    }
  }
  .results-list {
    border: 1px solid #707070;
    border-left: none;

    position: relative;
    margin: auto;
  }
  .result-item.experience {
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #707070;
    cursor: pointer;
    a {
      color: #000;
      text-decoration: none;
    }
    .result-thumbnail {
      width: 32.61%;
      padding-bottom: 32.61%;
      height: 0;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      flex: 0 0 auto;
    }
    .result-detail {
      padding: 4.25% 5.36%;
      flex: 1 1 auto;
    }
    .result-title {
      font-size: 17px;
      font-weight: bold;
    }
    .result-excerpt {
      font-size: 13px;
    }
  }
  .empty-result {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
}
</style>
