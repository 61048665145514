<template>
  <div class="map-popup" v-if="experience">
    <div class="map-popup-wrapper">
      <div class="close-button" @click="onClickClose">&times;</div>
      <header ref="header" class="popup-header">
        <div
          v-if="experience.thumbnailUrl"
          class="experience-thumbnail"
          :style="{ backgroundImage: `url(${experience.thumbnailUrl})` }"
        ></div>
      </header>
      <VuePerfectScrollbar ref="body" class="popup-body">
        <h4 class="experience-title">{{experience.title}}</h4>
        <div class="experience-description">{{experience.description}}</div>
        <div v-if="experience.address" class="experience-address" :style="{ backgroundImage: `url(${icons.marker})` }">
          <span>{{experience.address}}</span>
        </div>
        <div v-if="experience.what3words" class="experience-what3words" :style="{ backgroundImage: `url(${icons.what3words})` }">
          <span>{{experience.what3words}}</span>
        </div>
        <div v-if="experience.websiteLink || experience.email" class="experience-contact" :style="{ backgroundImage: `url(${icons.contact})` }">
          <span v-html="getContectText(experience)"></span>
        </div>
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import marker from "@/assets/img/map/marker.svg";
import what3words from "@/assets/img/map/what3words.svg";
import contact from "@/assets/img/map/contact.svg";

export default {
  name: "MapPopup",
  components: {
    VuePerfectScrollbar
  },
  props: ["experience"],
  data() {
    return {
      headerHeight: 0,
      icons: {
        marker,
        what3words,
        contact
      }
    };
  },
  methods: {
    onClickClose() {
      this.$emit("close");
    }
  },
  created() {
    if (this.$refs.header) {
      this.headerHeight = this.$refs.header.offsetHeight;
    }
  },
  getContectText(exp) {
    if (exp.websiteLink && exp.email) {
      return (exp.websiteLink + " " + exp.email).trim();
    } else if (exp.websiteLink) {
      return exp.websiteLink.trim();
    } else if (exp.email) {
      return exp.email.trim();
    }
  }
};
</script>

<style lang="scss" scoped>
.map-popup {
  position: absolute;
  left: 12px;
  top: 84px;
  z-index: 1002;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;

  .map-popup-wrapper {
    position: relative;
    display: block;
    width: 287px;
    height: 80vh;
    margin: 9px;
  }

  .close-button {
    display: block;
    position: absolute;
    right: -16px;
    top: -16px;
    z-index: 2;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: #e13036;
    line-height: 33px;
    text-align: center;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 33px;
    font-weight: lighter;
    cursor: pointer;
    user-select: none;
  }

  .popup-header {
    margin: 0 0 20px;
  }

  .popup-body {
    height: calc(100% - 240px);
    font-size: 12px;
  }

  .experience-thumbnail {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;

    &::before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      padding-bottom: 69.68%;
    }
  }

  .experience-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0 16px 16px;
  }

  .experience-address,
  .experience-what3words,
  .experience-contact {
    margin: 8px 16px;
  }

  .experience-description {
    margin: 16px;
  }

  .experience-address,
  .experience-what3words,
  .experience-contact {
    padding-left: 27px;
    background-repeat: no-repeat;
    background-position: center left;
  }
}
</style>                  